//import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import logoIconW from './icon/HAPPYHAIR_w.png'
import { createStore } from 'redux'
import { Link, useParams } from 'react-router-dom';

let surveyID = 'MA==575NA==150'
let url = null
let env = process.env.NODE_ENV
if (env == "production") {
    url = "https://survey-server.happyhitnow.com"
} else {
    url = "http://localhost:8080"

}
const $=window.$


const initState = {
    questionList: {
        //questionNumber:answer
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: "",
    },//答案組

    complain: {
        q1: "",
        q2: "",
        q3: "",
        q4: "",
        q5: "",
        q6: "",
    },//答
    url: url,
    survey: {
        name: null,
        brand: null,
        orderID: null,
        orderDate: null,
        unitID: null,
        unitName: null,
    },

}


const reducer = (state = initState, action) => {
    switch (action.type) {
        case "add":
            state.answer[action.question] = action.answer
            return state
        case "rest":
            state.answer = {}
            return state
        case "update":
            state.questionList[action.questionNumber] = action.answer
            return state
        case "complain":
            state.complain[action.questionNumber] = action.complain
            return state
        case "init":
            state.survey.surveyID = action.surveID
            state.survey.name = action.name
            state.survey.brand = action.brand
            state.survey.orderID = action.orderID
            state.survey.orderDate = action.orderDate
            state.survey.unitID = action.unitID
            state.survey.unitName = action.unitName
            return state

        default:
            return state
    }
}





const store = createStore(reducer)


function ButtonList(props) {
    //按鈕
    let classPickName = `div.q${props.questionNumber}`
    let colorFalse = `btn alert-dark m-1 font-weight-bold  rounded-circle q${props.questionNumber}`
    let colorTrue = `btn btn-danger m-1 font-weight-bold rounded-circle q${props.questionNumber}`
    const [complain, setComplain] = useState(null)
    const [textContent, setContent] = useState("")


    function handleButtonClick(e) {


        let buttonArray = document.querySelectorAll(classPickName)
        //console.log(buttonArray)
        for (let x = 0; x < buttonArray.length; x++) {
            buttonArray[x].className = colorFalse
        }
        let action = {
            type: "update",
            questionNumber: props.questionNumber,
            answer: parseInt(e.target.id, 10),

        }

        if (parseInt(e.target.id, 10) < 4) {
            document.getElementById(`complain${props.questionNumber}`).hidden = false

        } else {
            document.getElementById(`complain${props.questionNumber}`).hidden = true
        }
        //整理按鈕頻色
        for (let i = 0; i < buttonArray.length; i++) {


            if (buttonArray[i].id == e.target.id) {
                buttonArray[i].className = colorTrue
            } else {
                buttonArray[i].className = colorFalse
            }

        }

        store.dispatch(action)
        // console.log(store.getState())
    }

    let buttonList = []
    for (let x = 0; x < 5; x++) {
        let tempButton = <div className={colorFalse} id={x + 1} key={x} onClick={handleButtonClick}>{x + 1}</div>
        buttonList.push(tempButton)
    }

    function handleText(k) {

        setContent(k.target.value)
        let action = {
            type: "complain",
            questionNumber: props.questionNumber,
            complain: k.target.value,
        }
        store.dispatch(action)
        console.log(store.getState())
    }

    let complanID = `complain${props.questionNumber}`



    return (
        <>
            <div className="text-center">
                {buttonList}
                <h6 className="Light weight text text-muted"> (不滿意 ... 滿意)</h6>
                <div id={complanID} className="card-body  bg-light rounded" hidden={true}>
                    <h6 className="card-title  text-muted">請讓我們有機會了解您的想法..</h6>
                    <hr />
                    <textarea className="rounded border-light text-muted  shadow-textarea form-control" onChange={handleText} rows="5" value={textContent}></textarea>

                </div>
            </div>
        </>
    )
}

const Question = (props) => {
    let title = props.text


    function handleClick(e) {

        //alert(id)
        let action = {
            type: "add",
            question: e.target.id,
            answer: e.target.innerText,
        }
        store.dispatch(action)
        console.log(store.getState())



    }
    return (
        <>
            <div className="card-body mt-3 bg-light rounded text-ceter">
                <h5 className="card-title mt-3 text-muted">{title}</h5>
                <hr />

                <ButtonList className="ml-auto mr-auto" questionNumber={props.questionNumber} />
                
            </div>
        </>
    )
}

const QuestionYN = (props) => {
    let title = props.text
    let questionNumber = props.questionNumber
    let classPickName = `div.q${props.questionNumber}`
    let colorFalse = `btn alert-dark m-1 font-weight-bold border q${props.questionNumber}`
    let colorTrue = `btn btn-danger m-1 font-weight-bold q${props.questionNumber}`

    const [textContent, setContent] = useState("")
    function handleButtonClick(e) {

        let buttonArray = document.querySelectorAll(classPickName)
        console.log(buttonArray)
        for (let x = 0; x < buttonArray.length; x++) {
            buttonArray[x].className = colorFalse
        }
        let action = {
            type: "update",
            questionNumber: props.questionNumber,
            answer: parseInt(e.target.id, 10),

        }

        //整理按鈕頻色
        for (let i = 0; i < buttonArray.length; i++) {


            if (buttonArray[i].id == e.target.id) {
                buttonArray[i].className = colorTrue
            } else {
                buttonArray[i].className = colorFalse
            }

        }

        store.dispatch(action)
        // console.log(store.getState())


        if (parseInt(e.target.id, 10) != 1) {
            document.getElementById(`complain${props.questionNumber}`).hidden = false

        } else {
            document.getElementById(`complain${props.questionNumber}`).hidden = true
        }
    }
    function handleText(k) {

        setContent(k.target.value)
        let action = {
            type: "complain",
            questionNumber: props.questionNumber,
            complain: k.target.value,
        }
        store.dispatch(action)
        // console.log(store.getState())
    }
    let complanID = `complain${props.questionNumber}`
    return (
        <>
            <div className="card-body mt-3 bg-light rounded ">
                <h5 className="card-title mt-3 text-muted">{title}</h5>
                <hr />
            <div className="text-center">
            <div className={colorFalse} id={1} onClick={handleButtonClick}>願意</div>
                <div className={colorFalse} id={2} onClick={handleButtonClick}>不願意</div>

            </div>
               
                <div id={complanID} className="card-body  bg-light rounded" hidden={true}>
                    <h6 className="card-title  text-muted">請讓我們知道您的想法..</h6>
                    <hr />
                    <textarea className="rounded border-light text-muted  shadow-textarea form-control" onChange={handleText} rows="5" value={textContent}></textarea>

                </div>

            </div>
        </>
    )
}

const QuestionAnswer = (props) => {
    let aa = store.getState()
    //console.log(aa.answer["6"])
    const [textContent, setContent] = useState(null)
    let title = props.text
    let questionNumber = props.questionNumber

    function handleClick(e) {
        setContent(e.target.value)
        //alert(id)
        let action = {
            type: "update",
            questionNumber: e.target.id,
            answer: e.target.value,
        }
        store.dispatch(action)
        console.log(store.getState())
    }
    return (
        <>
            <div className="card-body mt-3 bg-light rounded">
                <h5 className="card-title mt-3 text-muted">{title}</h5>
                <hr />

                <textarea className="rounded border-light text-muted  shadow-textarea form-control" id={questionNumber} onChange={handleClick} rows="5" value={textContent}></textarea>



            </div>
        </>
    )
}


const MainBody = () => {
    //取出survey server url
    let param = useParams() //取得客戶ID
    let orderID = param.orderID
    let userID=param.userID
    const [msg, setMsg] = useState(null);
    console.log(orderID,userID)
    const [name, setName] = useState('')
    const [orderDate, setOrderDate] = useState('')
    const [brand, setBrand] = useState('')
    const [unitName, setUnitName] = useState('')
    const [userName,setUserName]=useState("")
    const [status,setStatus]=useState(true)
    
    
    function getSurveyData() {
        let url = store.getState().url
        fetch(`${url}/FNSurvey/${orderID}/${userID}`).then((res) => {
        
                if (res.status!=200){
                    setStatus(false)
                }
            return res.json();
        }).then(json => {
           
        if (json){
            let action = {
                type: "init",
                surveyID: surveyID,
                name: json.name,
                brand: json.brand,
                orderID: json.orderID,
                orderDate: json.orderDate,
                unitName: json.unitName,
                unitID:json.unitID,
            }
            store.dispatch(action)
            setName(json.name)
            setOrderDate(json.orderDate.substring(0, 4)+"/"+json.orderDate.substring(4, 6)+"/"+json.orderDate.substring(6, 8))
            setUnitName(json.unitName)
            setBrand(json.brand)
            setUserName(json.userName)

            
            console.log(store.getState())
        }
           

        })
    }





    function handleSummit(e) {
        let answer = store.getState()
        console.log("answer", answer.questionList)
        let notAnswer = []
      
        let y=0
        for (const key in  answer.questionList) {
            y++
            if (!answer.questionList[key] && y<6){
                notAnswer.push(key)
            }
            console.log(key, answer.questionList[key]);
        }

        if (notAnswer.length > 0) {
            // alert(notAnswer)
            
            setMsg(`Sorry～還有 ${notAnswer.length} 項目沒有作答～感謝您`);
            $("#donePopMsgModal").modal("show");
            
        } else {
            //先處理送出 作答資料
            var formData = new FormData();
          
            formData.append("name", name);
            formData.append("userID", userID);
            formData.append("orderID", orderID);
            formData.append("q1", store.getState().questionList.q1);
            formData.append("q2", store.getState().questionList.q2);
            formData.append("q3", store.getState().questionList.q3);
            formData.append("q4", store.getState().questionList.q4);
            formData.append("q5", store.getState().questionList.q5);
            formData.append("q6", store.getState().questionList.q6);
            formData.append("c1", store.getState().complain.q1);
            formData.append("c2", store.getState().complain.q2);
            formData.append("c3", store.getState().complain.q3);
            formData.append("c4", store.getState().complain.q4);
            formData.append("c5", store.getState().complain.q5);
            console.log('formData',formData)
            fetch(`${url}/addSurvey`, {
                method: "POST",
                body: formData,
                mode: "cors",
              })
                .then((res) => {
                  return res.json()
          
                }).then((json)=>{
                        console.log(json)
                        setMsg('感謝您的耐心作答，您的支持是我們最大的動力');
                        $("#donePopMsgModalfinish").modal("show");
                        return 
                 
                }).catch(err=>{
          
                  console.log('錯誤:', err);
                  alert('伺服器回應建立失敗')
                  window.location.href="https://www.happyhair.com.tw/"
                })
            // alert(store.getState())
           
        }
    }

    useEffect(() => {

        getSurveyData()
       


    }, 0)
    //主劃面

    
if (!status) {
    window.location.href="https://www.happyhair.com.tw/"
   return
}

let text5=`5.請問您是否願意再次蒞臨 ${brand} 品牌消費？`
    return (
        <>

            <div className="container m-auto p-1">

                <div className="card m-1 p-2 bg-dark rounded" >

                    <img src={logoIconW} className="card-img-top p-1  m-auto" style={{ "width": "200px" }} alt="..." />
                    <img src="https://d5w2wy9bedceo.cloudfront.net/ee191d2f547249f78fbd7837cd315b40/album/e486c87e818045f28db3bb40ff36678c/22650d3a1a1b4ed791071fe0a99814d0.jpg" className="card-img-top rounded " alt="..." />
                    <div className="card-body bg-light rounded mt-2">
                        <h5 className="card-title mt-3 text-left"><span className="text-left">親愛的貴賓 {name} :</span></h5>
                        <hr />
                        <p className="card-text text-left m-1 text-muted">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;非常感謝您選擇 {brand} 為時尚消費的造型首選，你的支持是對我們最大的鼓勵，在您 {orderDate} 到訪我們的 {unitName} 分店 由 {userName} 設計師為您服務之後，希望您撥空填寫以下問卷，更期待您再度蒞臨。</p>


                    </div>
                    <Question text="1.您對店內人員的服務態度是否感到滿意？" questionNumber="q1" />
                    <Question text="2.您對店內人員的專業技術是否感到滿意？" questionNumber="q2" />
                    <Question text="3.整體而言，您對店內環境空間是否感到滿意？" questionNumber="q3" />
                    <Question text="4.整體而言，您在店內感受的整體氛圍是否感到滿意？" questionNumber="q4" />
                    <QuestionYN text={text5} questionNumber="q5" />
                    <QuestionAnswer text="6.歡迎您分享本次的消費經驗與建議?" questionNumber="q6" />

                    <div className="card-body mt-3 bg-light rounded text-center">
                        <h5 className="card-title mt-3">感謝您的耐心作答</h5>
                        <hr />
                        <div className="btn btn-danger m-3" onClick={handleSummit}>送出作答</div>


                    </div>
                </div>

            </div>

            {/** ----未完成 */}
      <div
        className="modal fade"
        id="donePopMsgModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        modal="show"
      >
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {brand}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{msg}</div>
            <div className="modal-footer ">
              <div
                className="btn btn-danger ml-auto mr-auto"
                data-dismiss="modal"
                style={{ width: "100pt" }}
                
              >
                確定
              </div>
            </div>
          </div>
        </div>
      </div>

       {/** ----已完成 */}
       <div
        className="modal fade"
        id="donePopMsgModalfinish"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        modal="show"
      >
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {brand}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{msg}</div>
            <div className="modal-footer ">
              <div
                className="btn btn-danger ml-auto mr-auto"
                data-dismiss="modal"
                style={{ width: "100pt" }}
                onClick={()=>{
                    window.location.href="https://www.happyhair.com.tw/"
                    }
                }
                
              >
                確定
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}


export default MainBody;
