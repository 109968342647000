//import logo from './logo.svg';
import './App.css';
import React ,{useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import logoIcon from './icon/HAPPYHAIR_b.png'
import logoIconW from './icon/HAPPYHAIR_w.png'
import MainBody from'./MainBody'

const Home=()=>{
  
  window.location.href="https://www.happyhair.com.tw/"
}
//gcloud builds submit --tag asia.gcr.io/hitnow-v1/hitnow.2.0.1-survey-react-app
function App() {
  return (
    <>
      <Route path="/" exact component={Home} />
      <Route path="/:orderID/:userID"  component={ MainBody} />
     </>
 
  );
}

export default App;
